/* eslint-disable max-len */
export default {
  translation: {
    document_layout: {
      navbar_title: 'Content',
    },
    documents: 'Documents',
    patner_agreement: 'Partner Program Agreement',
    cookie_policy: 'Cookie policy',
  },
};
