import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enUS from './en-us';

i18n.use(initReactI18next).init({
  resources: {
    'en-US': enUS,
  },
  lng: 'en-US',
  fallbackLng: 'en-US',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
