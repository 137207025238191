import React, { lazy, Suspense } from 'react';
import { HashRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Loader, useToggleState, CookieConsent } from '@unistorecom/ui';
import { AppRoutes } from 'const/routes';

import './index.scss';

const CookiePolicyPage = lazy(() => import('./pages/CookiePolicy'));
const PartnerAgreementPage = lazy(() => import('./pages/PartnerAgreement'));

const GOOGLE_ANALYTICS_ID = 'G-6VNRCD8GPN';
const GOOGLE_TAG_MANAGER_ID = 'GTM-NJD9L65K';
// const META_ID = '25467837036148143';
const COOKIE_NAME = '_tracking_consent_dashboard';
const cookie = document.cookie.match('(^|;)\\s*' + COOKIE_NAME + '\\s*=\\s*([^;]+)');

const App = () => {
  const [openConsentPopup, toggleOpenConsentPopup] = useToggleState(!cookie);

  return (
    <HashRouter>
      <Routes>
        <Route
          path={AppRoutes.COOKIE_POLICY}
          element={
            <Suspense fallback={<Loader />}>
              <CookiePolicyPage onOpenConsentPopup={toggleOpenConsentPopup} />
            </Suspense>
          }
        />
        <Route
          path={AppRoutes.PARTNER_AGREEMENT}
          element={
            <Suspense fallback={<Loader />}>
              <PartnerAgreementPage />
            </Suspense>
          }
        />
        <Route
          path="*"
          element={<Navigate to={AppRoutes.COOKIE_POLICY} replace />}
        />
      </Routes>
      <CookieConsent
        cookieName={COOKIE_NAME}
        open={openConsentPopup}
        linkCookiePolicy={`/#${AppRoutes.COOKIE_POLICY}`}
        onClose={toggleOpenConsentPopup}
        config={{
          ga: GOOGLE_ANALYTICS_ID,
          gtm: GOOGLE_TAG_MANAGER_ID,
          // meta: META_ID,
        }}
      />
    </HashRouter>
  );
};

export default App;
